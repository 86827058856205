<!--
* @Description:学习中心->我的作业
-->
<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              河南省团干部网络教育平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass"
            >培训班次</router-link
          >
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
          <router-link active-class="active" to="/newcomerPost"
            >新人驿站</router-link
          >
          <router-link active-class="active" to="/liveRoom"
            >直播大厅</router-link
          >
          <router-link active-class="active" to="/boutiqueCourse"
            >精品课程</router-link
          >
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class="navLocation">
        <span v-show='this.studentCourseId==0'>学习中心></span> 我的作业
      </div>
      <div class='workContent'>
          <div class="examList">
            <div class="titleWrap"><div class="title">课程</div></div>
            <ul class='examListbox'>
              <li class='examItem' :class="{'activeExamItem':index==chooseIndex}" v-for="(item,index) in recordsData" @click='clickExamItem(item,index)' :key='index'>
                <div class="examName">{{item.courseTitle}}</div>
              </li>
            </ul>
        </div>
        <div class="contentRight">
          <div class="titleWrap"><div class="title" v-if='this.recordsData.length>0'>{{this.recordsData[chooseIndex].courseTitle}}</div></div>
          <div class='topTip' v-show="homeWorkList.length>0">课时</div>
          <div class='topTip' v-show='homeWorkList.length==0'>暂无作业</div>
          <ul class='homeListbox'>
              <li class='homeItem' :class="{'lightItem':(index%2) === 0}" v-for="(item,index) in homeWorkList"  :key='index'>
                <div class="homeName">{{item.chapterTitle}}</div>
                <div v-if='$route.query.courseId==0' class='uploadType'>
                  <span class='showIcon okShowIcon' v-if="item.fileId && item.fileId!=''" @click='hasSubmitDialogVisibleShow(item)'>已完成</span>
                  <span class='showIcon' v-else @click='submitDialogVisibleShow(item.id,item.chapterWork)'>上传</span>
                </div>
                <div v-else class='uploadType'>
                  <span class='showIcon okShowIcon' v-if="item.fileId && item.fileId!=''" @click='hasSubmitDialogVisibleShow(item)'>已完成</span>
                  <span class='showIcon' v-else @click='submitDialogVisibleShow(item.id,item.chapterWork)'>上传</span>
                </div>
              </li>
          </ul>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
    <el-dialog
      class='submitWorkDialog'
      title="作业详情"
      :visible.sync="submitDialogVisible"
      :close-on-click-modal='false'
      :close-on-press-escape='false'
      append-to-body
      width="800px">
      <el-dialog
        width="457px"
        title="删除提醒"
        :visible.sync="innerVisible"
        append-to-body>
        <div style='font-size:16px;font-weight:800;padding:20px 0px'>您正在<span style='color:red'>删除</span>当前作业，请确认是否删除？</div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="removeFile">确定</el-button>
        </div>
      </el-dialog>
      <div class='diolagContent'>
        <div class='workContent'>{{chapterWork}}</div>
        <div style="font-size:20px">上传文件</div>
        <el-divider></el-divider>
        <!-- <div class='title' v-show="ossFileId==''">请选择文件进行上传</div> -->
        <div class='imageWrap'>
          <el-upload
            ref='upload'
            class="upload-demo"
            accept=".pdf,.mp3,.mp4,.docx,.wav,.ppt"
            :limit="1"
            :show-file-list='false'
            :on-success='submitSucess'
            :on-error='submitError'
            :headers="myHeaders"
            :action="submitUrl">
            <img v-show="ossFileId==''" src="../../assets/uploadIcon.png" alt="">
          </el-upload>
          <div class='tip' v-show="ossFileId==''">提醒：仅支持pdf, mp3, mp4, docx, wav, ppt格式</div>
          <div class='fileWrap' v-show="ossFileId!=''">
            <i class='el-icon-document-checked'></i>
            <div class='fileName'>{{uploadFileName}}</div>
          </div>
          <div v-show="ossFileId!=''" class='removeFileWrap' @click='innerVisible=true'>
            <i class='el-icon-delete'></i>
          </div>
        </div>
        <div class='upWorkBtn'><button @click='submitWorkFile'>提交作业</button></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
const TOKEN = localStorage.getItem('token') ? localStorage.getItem('token') : ''
const baseUrl = process.env.VUE_APP_SERVER_IP
export default {
  name: '',
  components: {
    footerCom
  },
  props: {},
  watch: {},
  data () {
    return {
      submitUrl: baseUrl + '//common/upload',
      userName: '',
      chooseIndex: 0,
      homeWorkList: [],
      studentCourseId: '',
      submitDialogVisible: false,
      innerVisible: false,
      workId: '',
      ossFileId: '',
      uploadFileName: '',
      chapterWork: '',
      myHeaders: {'X-Access-Token': TOKEN},
      recordsData: []
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    clickExamItem (item, index) {
      console.log(item)
      this.chooseIndex = index
      this.getHomeWorkList(item.id)
    },
    // 获取作业
    getHomeWorkList (courseId) {
      let data = {
        'studentCourseId': courseId // 学员课程ID
      }
      this.$axios.post(this.$apiUrl.courseListWorks, {params: data}).then(response => {
        let res = response.data
        if (res.success) {
          let resData = res.data
          this.homeWorkList = JSON.parse(JSON.stringify(resData))
        }
      })
    },
    // 获取左侧课程
    getRecordsData () {
      return new Promise((resolve, reject) => {
        let reqOption = {
          'pageNo': 1,
          'pageSize': 999,
        }
        this.$axios.post(this.$apiUrl.queryStudentCoursePage, reqOption).then(res => {
          console.log(res)
          let {records} = res.data.data
          resolve(records)
        })
      })
    },
    submitDialogVisibleShow (workId, chapterWork, fileName) {
      this.chapterWork = chapterWork
      this.ossFileId = ''
      this.submitDialogVisible = true
      this.workId = workId
    },
    hasSubmitDialogVisibleShow (item) {
      let {id, chapterWork, fileName, fileId} = item
      this.chapterWork = chapterWork
      this.ossFileId = fileId
      this.uploadFileName = fileName
      this.workId = id
      this.submitDialogVisible = true
    },
    submitSucess (response, file, fileList) {
      console.log(response, file, fileList)
      if (response.success) {
        this.ossFileId = response.data.ossFileId
        this.uploadFileName = file.name
        this.$message({
          type: 'success',
          message: '上传成功！'
        })
        this.$nextTick(() => {
          this.$refs.upload.clearFiles() // 上传成功之后清除历史记录
        })
      }
    },
    submitError (err, file, fileList) {
      console.log(err, file, fileList)
      this.$message({
        type: 'error',
        message: '上传失败，请重试！'
      })
    },
    removeFile () {
      this.innerVisible = false
      this.ossFileId = ''
      this.uploadFileName = ''
    },
    // 提交作业
    submitWorkFile () {
      if (this.ossFileId) {
        this.$axios.post(this.$apiUrl.addStudentWork, {
          fileId: this.ossFileId,
          studentChapterId: this.workId
        }).then(response => {
          let res = response.data
          if (res.success) {
            this.$message({
              type: 'success',
              message: '提交成功',
            })
            this.submitDialogVisible = false
            this.getHomeWorkList(this.recordsData[this.chooseIndex].id)
          } else {
            this.$message({
              type: 'error',
              message: res.msg,
            })
          }
        })
      } else {
        this.$message({
          type: 'error',
          message: '请先提交答案',
        })
      }
    }
  },
  async mounted () {
    this.studentCourseId = this.$route.query.courseId
    this.getUserInfo()
    // 学习中心跳转过来
    if (this.$route.query.courseId == 0) {
      let records = await this.getRecordsData()
      this.recordsData = JSON.parse(JSON.stringify(records))
      this.getHomeWorkList(this.recordsData[0].id)
    } else {
      let records = await this.getRecordsData()
      console.log(records, this.$route.query.courseId)
      this.recordsData = records.filter(item => {
        return item.id == this.$route.query.courseId
      })
      // 学习中心跳转过来
      this.getHomeWorkList(this.$route.query.courseId)
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .navLocation{
      margin-bottom: 30px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .workContent{
    display: flex;
    justify-content: space-around;
    margin-bottom: 100px;
    .titleWrap{
        padding: 0 30px;
        height: 59px;
        border-bottom: 1px solid rgba(112,112,112,0.2);
        .title{
          height: 58px;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #1C4ECC;
          line-height: 58px;
        }
      }
    .examList{
      width: 291px;
      background: #FFFFFF;
      box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      border: 1px solid rgba(0,0,0,0);
      min-height: 228px;
      .examListbox{
        padding: 20px 0;
        .examItem{
          display: flex;
          width: 100%;
          border-radius: 0px 0px 0px 0px;
          /* opacity: 0.26; */
          justify-content: space-between;
          align-items: center;
          color: #000000;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          padding: 10px 0px;
          cursor: pointer;
          .examName{
            padding-left: 20px;
            padding-right: 10px;
            border-left: 3px solid #fff;
            opacity: 1;
          }
          .time{
            padding: 0px 30px;
            opacity: 1;
          }
        }
        .activeExamItem{
          background: #E8F0FE;
          .examName{
            border-left: 3px solid #4774DF;
          }
        }
      }
    }
    .contentRight{
      width: 819px;
      min-height: 568px;
      background: #FFFFFF;
      padding: 0px 32px 0px 21px;
      box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      .title{
        color: #000000;
          height: auto;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          padding: 5px 0px
      }
      .topTip{
        height: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 48px;
        padding-left: 30px;
      }
      .homeItem{
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .uploadType{
          width: 100px;
          text-align:center
        }
        .showIcon{
          font-size: 18px;
          color: #1C4ECC;
          cursor: pointer;
        }
        .okShowIcon{
          color: #FF6F00
        }
      }
      .lightItem{
        background-color: #F3F7FF
      }
    }
    }
  }
  .diolagContent{
    padding: 0px 30px;
    padding-bottom: 30px;
    text-align: left;
    .workContent{
      font-size: 18px;
      padding-top: 20px;
      margin-bottom: 30px;
      font-weight: bold;
    }
    /deep/ .el-divider{
      margin-top: 12px;
    }
    .title{
      font-size: 14px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #FF6767;
      margin-bottom: 20px;
    }
    .imageWrap{
      margin-bottom: 20px;
      width: 220px;
      background: #F2F2F2;
      border-radius: 2px 2px 2px 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0px 40px;
      position: relative;
      .upload-demo{
        width: 100%;
        /deep/ .el-upload{
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
      ::v-deep .el-upload-dragger{
          width: 99px;
          height: 102px;
          border: 0px;
        img{
          width: 99px;
          height: 102px;
        }
      }
      ::v-deep .el-upload-list{
          padding: 0px 40px;
      }
    }
    .tip{
      width: 140px;
      font-size: 12px;
      font-family: Microsoft YaHei-Light, Microsoft YaHei;
      font-weight: 300;
      color: #000000;
    }
    .fileWrap{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      i{
        font-size: 50px;
        margin-bottom: 10px;
      }
      .fileName{
        font-size: 14px;
        padding: 0px 20px;
      }
    }
    .removeFileWrap{
      position: absolute;
      bottom: 10px;
      right: 15px;
      cursor: pointer;
      i{
        font-size: 15px;
      }
      :hover{
        color: red
      }
    }
    .upWorkBtn{
      text-align: right;
      button{
        width: 200px;
        height: 40px;
        background: #FF6F00;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        border: 2px solid #FF6F00;
        text-align: center;
        color: #ffff;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }
</style>
